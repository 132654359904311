export const howToOrganizeProperties = {
  order: 1,
  name: "Organize properties",
  keywords: "park campus mixed-use outparcel pad site",
  subcategory: "Properties",
  markdown: `# Organize properties

  {% inlineRouterLink articleId="what-is-a-property?" %}Properties{% /inlineRouterLink %} are a fundamental concept in Tower Hunt. They act as containers for property rights and land coverings, enabling a wide variety of physical real estate scenarios. With this freedom comes a question: how should I organize properties? Read on for some best practices.

  {% callout type="tip" %}
  **Tip:** Remember that the property diagram can {% inlineRouterLink %}display multiple properties{% /inlineRouterLink %}, so don't be afraid to create multiple nearby properties as long as it makes sense to do so.
  {% /callout %}

  ## Standalone buildings

  The most common situation is a single building that sits atop a single fee parcel and is operated on its own. Every building exists within a submarket and has neighbors, but often the ownership and operation of these nearby buildings is distinct from and unrelated to the subject building. Treat this type of building as a single property, inclusive of any parking surfaces/structures that are utilized by the building.

  ## Branded campuses & parks

  One situation that often makes sense to build as a single property is a branded campus or park. If some or all of these characteristics are present, proceed with a single property:

  - Multiple buildings sharing a branded name and/or logo
  - Multiple buildings served by a single access road
  - Multiple buildings designed and arranged to be operated as a collection

  ## Large-scale mixed-use structures

  Some large buildings contain multiple uses, each of which may be owned and operated separately. Treat this as a single property, using {% inlineRouterLink %}building groups{% /inlineRouterLink %} and {% inlineRouterLink %}ownership units{% /inlineRouterLink %} to isolate different parts of the whole.

  ## Shopping malls with outparcels

  Many malls, especially larger ones, contain outparcels (aka pad sites). Even if multiple owners are present, it usually makes visual sense to treat these sites as a single property.`,
};
